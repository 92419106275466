import * as React from 'react';

import { Text, BlogImage, BlogLink, BlogLiterature } from '../../../components';

import { PageNew, SectionBlog } from '../../../fragments';

import kAndG from '../../../images/educational-corner/blog30/kokolingo-k-i-g.png';
import kokolingo from '../../../images/educational-corner/blog30/kokolingo.png';
import therapistAndChild from '../../../images/educational-corner/blog30/logoped-i-dijete.jpg';

const VoicesKAndG = () => {
  return (
    <PageNew
      title="KOKOLINGO - Izgovor i terapija glasova K i G"
      description="Glasovi K i G  spadaju u istu podskupinu glasova jer se 
      izgovaraju podizanjem stražnjeg dijela jezika (stražnjonepčani ili velarni glasovi). 
      U izgovoru glasova K i G vršak jezika je spušten, a stražnji dio leđa jezika 
      stvara pregradu s tvrdim nepcem. Glasovi K i G se razlikuju samo po vibraciji 
      glasnica, odnosno glas G je zvučan, a K bezvučan. Položite dlan na svoje grlo 
      prilikom izgovaranja K i G pa ćete uočiti kako „grlo miruje“ pri izgovoru K, a „bruji“ 
      pri izgovoru glasa G."
      headline="Izgovor i terapija glasova K i G"
    >
      <Text as="h2">Kako se pravilno izgovaraju glasovi K i G?</Text>
      <Text as="p">
        Glasovi K i G spadaju u istu podskupinu glasova jer se izgovaraju
        podizanjem stražnjeg dijela jezika (stražnjonepčani ili velarni
        glasovi).
      </Text>
      <Text as="p">
        U izgovoru glasova K i G vršak jezika je spušten, a stražnji dio leđa
        jezika stvara pregradu s tvrdim nepcem. Glasovi K i G se razlikuju samo
        po vibraciji glasnica, odnosno glas G je zvučan, a K bezvučan. Položite
        dlan na svoje grlo prilikom izgovaranja K i G pa ćete uočiti kako „grlo
        miruje“ pri izgovoru K, a „bruji“ pri izgovoru glasa G.
      </Text>
      <BlogImage
        imgAlt="Glasovi K i G"
        imgTitle="Glasovi K i G"
        imgSrc={kAndG}
      />
      <Text as="h2">Teškoće izgovaranja glasova K i G</Text>
      <Text as="p">
        Poremećaji izgovora ovih glasova nazivaju se <b>kapacizam</b> i{' '}
        <b>gamacizam</b> i relativno su rijetki. Pogrešno izgovaranje navedenih
        glasova tolerira se do otprilike 3. godine života. Najčešće djeca glas K
        mijenjaju glasom T, a glas G glasom D.
      </Text>
      <Text as="h2">Ispravljanje glasova K i G na logopedskim terapijama</Text>
      <Text as="p">
        Za ispravljanje navedenih glasova, u pravilu, potrebno je kraće vrijeme
        nego za ispravak drugih glasova (oko 8 logopedskih terapija). Jednom
        kada dijete uspješno ostvari kontakt leđa jezika s nepcem, obično ga
        prilično brzo automatizira u svakodnevnom govoru. No, kako se radi o
        izgovoru glasova u stražnjem dijelu usta, ponekad je djetetu teško
        vizualno predočiti i objasniti položaj izgovornih organa.
      </Text>
      <Text as="p">
        Tijekom pripremnog razdoblja uvježbavamo dijete da jezikom napravi oblik
        „planine“. Tu nam može pomoći gusar Srećko i njegova artikulacijska
        gimnastika, u kojoj se kroz niz predvježbica dijete postupno podučava da
        jezik oblikuje u navedeni položaj.
      </Text>
      <BlogImage
        imgAlt="Logoped i dijete vjezbaju izgovor glasova K i G"
        imgTitle="Logoped i dijete"
        imgSrc={therapistAndChild}
      />
      <Text as="p">
        Ako glas K nije dobiven oponašanjem pred ogledalom, primjenjuje se
        mehanička metoda formiranja glasa K od glasa T pomoću sonde ili špatule.
        Od djeteta se traži da izgovara slogove „ta-ta-ta“ te mu se nakon toga
        prednji dio jezika pritisne dolje i malo unazad. Tada se od T postupno
        formira K. Dijete se može podučiti i da vlastitim prstićem pridržava vrh
        jezika dolje. Kasnije se pokušava proizvesti K bez mehaničkog pritiska.
      </Text>
      <Text as="p">
        K i G se u pravilu ispravljaju paralelno, s obzirom na to da se
        razlikuju samo po elementu zvučnosti.
      </Text>
      <Text as="h2">Metode koje mogu pomoći</Text>
      <Text as="p">
        Kao što je prije navedeno, može biti teško djetetu objasniti što jezikom
        treba činiti, s obzirom na to da se jezik u tom položaju ne vidi dobro.
        Lakše je, primjerice, objasniti kako se izgovara glas L, jer se izgovara
        u prednjem dijelu usta i položaj jezika je jasno vidljiv u ogledalu.
      </Text>
      <Text as="p">
        Sa svakim djetetom naposlijetku „upali“ jedan od navedenih trikova:
        <ol>
          <li>
            Sjesti s djetetom pred ogledalom i jako otvorenih usta pokušati
            izgovarati riječi koje počinju na K (kava, koka, Kiki)
          </li>
          <li>
            Djetetu lizalicom pridržavati vrh jezika dok ponavlja riječi koje
            počinju glasovima K i G
          </li>
          <li>
            Izvoditi vježbe u ležećem položaju kako bi gravitacija pomogla u
            podizanju stražnjeg jezika prema tvrdom nepcu
          </li>
          <li>Oponašati zvuk kašljanja, može i sa zabačenom glavom unazad</li>
          <li>Oponašati zvuk režanja dinosaura („grrr“ – s engleskim R)</li>
          <li>
            Grgljati tekućinu, jer kako bi zadržalo tekućinu u ustima, dijete će
            morati podizati stražnji dio jezika
          </li>
        </ol>
      </Text>
      <Text as="p">
        Kada dijete uspješno proizvede glasove izolirano, krećemo ih odmah
        izgovarati u riječima, a potom u kraćim rečenicama te u dužim
        tekstovima, u čemu nam može pomoći vesela papigica Koko u digitalnoj
        logopedskoj vježbenici<BlogLink> Kokolingo</BlogLink>.
      </Text>
      <BlogImage imgAlt="Kokolingo" imgTitle="Kokolingo" imgSrc={kokolingo} />
      <Text as="p">Ema Tadić, mag. logoped.</Text>
      <BlogLiterature>
        <ul>
          <li>
            Posokhova, I. (2005).{' '}
            <i>
              Izgovor: Kako ga poboljšati: rad na razvijanju pravilnog izgovora
              glasova u djece
            </i>
            . Zagreb: Ostvarenje.
          </li>
          <li>
            <a
              href="https://talktools.com/blogs/from-the-experts/k-sound-dean-trout"
              rel="noopener noreferrer"
              target="_blank"
            >
              <b>
                https://talktools.com/blogs/from-the-experts/k-sound-dean-trout
              </b>
            </a>
          </li>
          <li>
            <a
              href="https://www.youtube.com/watch?v=megGv7jH0a0&ab_channel=CarrieClark%2CSLPSolution"
              rel="noopener noreferrer"
              target="_blank"
            >
              <b>
                https://www.youtube.com/watch?v=megGv7jH0a0&ab_channel=CarrieClark%2CSLPSolution
              </b>
            </a>
          </li>
        </ul>
      </BlogLiterature>
      <SectionBlog />
    </PageNew>
  );
};

export default VoicesKAndG;
